body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-size: 1em;
  line-height: 1.35;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

::-moz-selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

a {
  text-decoration: none;
}

a:focus,
a:hover,
a:active {
  outline: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 0;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q::before,
q::after {
  content: "";
}

small {
  font-size: 85%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

nav ul,
nav ol,
ul,
li {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type=file] {
  cursor: pointer;
}
input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type=text],
input[type=email],
input[type=password],
input[type=search],
textarea {
  box-shadow: none;
  appearance: none;
}

input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  display: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid,
textarea:invalid {
  background-color: #f0dddd;
}

input,
textarea,
select {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: transparent;
}

td {
  vertical-align: top;
}

@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-Medium.eot");
  src: url("/assets/fonts/whitney/Whitney-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-Medium.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-Medium.woff") format("woff"), url("/assets/fonts/whitney/Whitney-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-MediumItalic.eot");
  src: url("/assets/fonts/whitney/Whitney-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-MediumItalic.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-MediumItalic.woff") format("woff"), url("/assets/fonts/whitney/Whitney-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-Bold.eot");
  src: url("/assets/fonts/whitney/Whitney-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-Bold.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-Bold.woff") format("woff"), url("/assets/fonts/whitney/Whitney-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-BoldItalicSC.eot");
  src: url("/assets/fonts/whitney/Whitney-BoldItalicSC.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-BoldItalicSC.woff") format("woff"), url("/assets/fonts/whitney/Whitney-BoldItalicSC.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-Light.eot");
  src: url("/assets/fonts/whitney/Whitney-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-Light.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-Light.woff") format("woff"), url("/assets/fonts/whitney/Whitney-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-LightItalic.eot");
  src: url("/assets/fonts/whitney/Whitney-LightItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-LightItalic.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-LightItalic.woff") format("woff"), url("/assets/fonts/whitney/Whitney-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-Book.eot");
  src: url("/assets/fonts/whitney/Whitney-Book.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-Book.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-Book.woff") format("woff"), url("/assets/fonts/whitney/Whitney-Book.ttf") format("truetype");
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-BookItalic.eot");
  src: url("/assets/fonts/whitney/Whitney-BookItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-BookItalic.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-BookItalic.woff") format("woff"), url("/assets/fonts/whitney/Whitney-BookItalic.ttf") format("truetype");
  font-weight: 350;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-Semibold.eot");
  src: url("/assets/fonts/whitney/Whitney-Semibold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-Semibold.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-Semibold.woff") format("woff"), url("/assets/fonts/whitney/Whitney-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-SemiboldItalic.eot");
  src: url("/assets/fonts/whitney/Whitney-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-SemiboldItalic.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-SemiboldItalic.woff") format("woff"), url("/assets/fonts/whitney/Whitney-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-BlackSC.eot");
  src: url("/assets/fonts/whitney/Whitney-BlackSC.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-BlackSC.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-BlackSC.woff") format("woff"), url("/assets/fonts/whitney/Whitney-BlackSC.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-BlackItalic.eot");
  src: url("/assets/fonts/whitney/Whitney-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-BlackItalic.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-BlackItalic.woff") format("woff"), url("/assets/fonts/whitney/Whitney-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.woff2") format("woff2"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.woff") format("woff"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.ttf") format("truetype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.woff2") format("woff2"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.woff") format("woff"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.ttf") format("truetype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.woff2") format("woff2"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.woff") format("woff"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.ttf") format("truetype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/google/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("/assets/fonts/google/MaterialIcons-Regular.woff2") format("woff2"), url("/assets/fonts/google/MaterialIcons-Regular.woff") format("woff"), url("/assets/fonts/google/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.button {
  position: relative;
  display: flex;
  justify-content: center;
  height: 50px;
  line-height: 46px;
  min-width: 300px;
  padding: 0 34px;
  align-self: center;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  background-color: #063A89;
  border: 2px solid #063A89;
  border-radius: 25px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .button {
    min-width: 0;
    padding: 0 20px;
    font-size: 12px;
  }
}
.button:hover {
  background-color: #042E59;
  border-color: #042E59;
}

.button--arrow:hover:before {
  margin-right: 0;
  margin-left: 13px;
}
.button--arrow:before, .button--arrow:after {
  display: block;
  content: "";
  background-color: #ffffff;
  border-color: #ffffff;
}
.button--arrow:before {
  align-self: center;
  order: 1;
  height: 2px;
  width: 9px;
  margin-right: -2px;
  margin-left: 10px;
  background-color: #ffffff;
  transition: margin 100ms ease;
}
.button--arrow:after {
  align-self: center;
  order: 2;
  width: 0;
  height: 0;
  background-color: transparent !important;
  border: 5px solid;
  border-bottom: 5px solid transparent !important;
  border-left: 5px solid transparent !important;
  transform: rotate(45deg);
}

.button--arrow--back:hover:before {
  margin-left: 0;
  margin-right: 13px;
}
.button--arrow--back:before, .button--arrow--back:after {
  display: block;
  content: "";
  background-color: #ffffff;
  border-color: #ffffff;
}
.button--arrow--back:before {
  align-self: center;
  order: 0;
  height: 2px;
  width: 9px;
  margin-left: -2px;
  margin-right: 10px;
  background-color: #ffffff;
  transition: margin 100ms ease;
}
.button--arrow--back:after {
  align-self: center;
  order: -1;
  width: 0;
  height: 0;
  background-color: transparent !important;
  border: 5px solid;
  border-top: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  transform: rotate(45deg);
}

.button--border {
  background-color: transparent;
  color: #063A89;
}
.button--border:before, .button--border:after {
  background-color: #063A89;
  border-color: #063A89;
}
.button--border:hover {
  background-color: #063A89;
  border-color: #063A89;
  color: #ffffff;
}
.button--border:hover:before, .button--border:hover:after {
  background-color: #ffffff;
  border-color: #ffffff;
}

.button--light-blue {
  background-color: #6CD1DD;
  border-color: #6CD1DD;
  color: #02162B;
}
.button--light-blue:hover {
  background-color: #3dc4d3;
  border-color: #6CD1DD;
}
.button--light-blue:before, .button--light-blue:after {
  background-color: #02162B;
  border-color: #02162B;
}
.button--light-blue.button--border {
  background-color: transparent;
  color: #6CD1DD;
  border-color: #6CD1DD;
}
.button--light-blue.button--border:before, .button--light-blue.button--border:after {
  background-color: #6CD1DD;
  border-color: #6CD1DD;
}
.button--light-blue.button--border:hover {
  background-color: #6CD1DD;
  color: #02162B;
}
.button--light-blue.button--border:hover:before, .button--light-blue.button--border:hover:after {
  background-color: #02162B;
  border-color: #02162B;
}

.container.buttons-container {
  margin: 50px 0;
}
.container.buttons-container .inner {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
.container.buttons-container .button {
  min-width: 422px;
  align-self: center;
}
@media (max-width: 767px) {
  .container.buttons-container .button {
    min-width: 0;
    max-width: 422px;
    width: 100%;
  }
}
.container.buttons-container .button + .button {
  margin-top: 30px;
}

.toggle {
  margin-top: 50px;
  display: inline-flex;
  border: 2px solid #6CD1DD;
  padding: 2px;
  border-radius: 25px;
}
.toggle div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  border-radius: 23px;
  height: 42px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 14px;
  color: #68D2DF;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.17em;
  cursor: pointer;
}
.toggle div.selected {
  background-color: #6CD1DD;
  color: #ffffff;
}

.form {
  margin: 70px 0;
}
@media (max-width: 767px) {
  .form .button {
    margin-top: 20px;
  }
}

.form__suptitle {
  margin-bottom: 9px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.17em;
  font-weight: 350;
  text-transform: uppercase;
  color: #02162B;
}

.form__title {
  margin-bottom: 23px;
}

.form__description {
  margin-bottom: 65px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 15px;
  line-height: 22px;
  font-weight: 350;
  color: #02162B;
}

.form__row {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .form__row {
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .form__row deltamax-slider-input {
    width: auto;
    align-self: flex-start;
  }
}

.form__row--double deltamax-unit-input, .form__row--double deltamax-select-input {
  width: calc(50% - 50px);
}
@media (max-width: 1024px) {
  .form__row--double deltamax-unit-input, .form__row--double deltamax-select-input {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .form__row--double deltamax-unit-input, .form__row--double deltamax-select-input {
    width: auto;
    align-self: flex-start;
  }
}
@media (max-width: 550px) {
  .form__row--double deltamax-unit-input, .form__row--double deltamax-select-input {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .form__row--double deltamax-unit-input .unit-input, .form__row--double deltamax-select-input .unit-input {
    align-self: center;
  }
}
@media (max-width: 1024px) {
  .form__row--double deltamax-unit-input .unit-input .unit-input__value__input, .form__row--double deltamax-select-input .unit-input .unit-input__value__input {
    width: 200px;
  }
}
@media (max-width: 767px) {
  .form__row--double deltamax-unit-input .unit-input .unit-input__value__input, .form__row--double deltamax-select-input .unit-input .unit-input__value__input {
    width: 300px;
  }
}
@media (max-width: 1024px) {
  .form__row--double deltamax-unit-input .unit-input .unit-input__value__unit, .form__row--double deltamax-select-input .unit-input .unit-input__value__unit {
    left: 210px;
  }
}
@media (max-width: 767px) {
  .form__row--double deltamax-unit-input .unit-input .unit-input__value__unit, .form__row--double deltamax-select-input .unit-input .unit-input__value__unit {
    left: 310px;
  }
}
.form__row--double > .unit-display, .form__row--double .unit-display__savings {
  width: calc(50% - 50px);
}
@media (max-width: 1024px) {
  .form__row--double > .unit-display, .form__row--double .unit-display__savings {
    width: 48%;
  }
}
@media (max-width: 767px) {
  .form__row--double > .unit-display, .form__row--double .unit-display__savings {
    width: 100%;
    align-self: center;
  }
}
@media (max-width: 550px) {
  .form__row--double > .unit-display, .form__row--double .unit-display__savings {
    width: 100%;
  }
}

.form__row--margin-bottom {
  margin-bottom: 30px;
}

hr {
  margin: 52px 0 70px;
  border-color: #D4D4D4;
}
@media (max-width: 767px) {
  hr {
    margin-top: 22px;
    margin-bottom: 40px;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 0.6px;
}

h1, .h1 {
  font-size: 62px;
  line-height: 60px;
  letter-spacing: 0.17em;
  font-weight: 500;
}
@media (max-width: 767px) {
  h1, .h1 {
    font-size: 42px;
    line-height: 44px;
  }
}
@media (max-width: 400px) {
  h1, .h1 {
    font-size: 30px;
    line-height: 32px;
  }
}

h2, .h2 {
  font-size: 32px;
  line-height: 35px;
  font-weight: 600;
  color: #02162B;
  text-align: center;
}

h3, .h3 {
  font-size: 32px;
  line-height: 35px;
  font-weight: 300;
}

h4, .h4 {
  font-size: 21px;
  line-height: 32px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.align-left {
  text-align: left;
}

* {
  box-sizing: border-box;
}
*:focus, *:active {
  outline: none;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.container .inner {
  max-width: 640px;
  width: 100%;
  padding: 0 20px;
}
.container .inner--big {
  max-width: 1000px;
  margin: 0 auto;
}
.container .inner--big {
  max-width: 1240px;
  margin: 0 auto;
}
.container .inner--extra-big {
  max-width: 1260px;
  margin: 0 auto;
}

@media (min-width: 1025px) {
  .outside-parent {
    margin-left: -100px;
    margin-right: -100px;
  }
}

.container.intro {
  margin-top: 145px;
  margin-bottom: 78px;
}
.container.intro h1 {
  max-width: 480px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}

.gradient {
  background: linear-gradient(180deg, #00378c 0%, #003586 7%, #021222 100%);
  color: #ffffff;
}
.gradient * {
  color: #ffffff;
}
.gradient label, .gradient .unit-input__value__unit {
  color: #D4D4D4 !important;
}
.gradient input {
  color: #ffffff !important;
}