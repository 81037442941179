@import "variables";
@import "breakpoints";

.form {
  margin: 70px 0;

  .button {
    @include mq('smartphone'){
      margin-top: 20px;
    }
  }
}

  .form__suptitle {
    margin-bottom: 9px;
    font-family: $whitney;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.17em;
    font-weight: 350;
    text-transform: uppercase;
    color: #02162B;
  }

  .form__title {
    margin-bottom: 23px;
  }

  .form__description {
    margin-bottom: 65px;
    font-family: $whitney;
    font-size: 15px;
    line-height: 22px;
    font-weight: 350;
    color: #02162B;
  }

  .form__row {
    display: flex;
    justify-content: space-between;

    @include mq('smartphone'){
      flex-direction: column;
      justify-content: center;
    }

    deltamax-slider-input {
      @include mq('smartphone'){
        width: auto;
        align-self: flex-start;
      }
    }
  }

    .form__row--double {
      deltamax-unit-input, deltamax-select-input {
        width: calc(50% - 50px);

        @include mq('tablet'){
          width: 50%;
        }

        @include mq('smartphone'){
          width: auto;
          align-self: flex-start;
        }

        @media (max-width: 550px) { 
          width: 100%;
        }

        .unit-input {
          @include mq('smartphone'){
            align-self: center;
          }
          
          .unit-input__value__input {
            @include mq('tablet'){
              width: 200px;
            }

            @include mq('smartphone'){
              width: 300px
            }
          }

          .unit-input__value__unit {
            @include mq('tablet'){
              left: 210px;
            }

            @include mq('smartphone'){
              left: 310px;
            }
          }
        }
      }

      >.unit-display, .unit-display__savings {
        width: calc(50% - 50px);

        @include mq('tablet'){
          width: 48%;
        }

        @include mq('smartphone'){
          width: 100%;
          align-self: center;
        }

        @media (max-width: 550px) { 
          width: 100%;
        }
      }
    }

    .form__row--margin-bottom {
      margin-bottom: 30px;
    }

hr {
  margin: 52px 0 70px;
  border-color: #D4D4D4;

  @include mq('smartphone'){
    margin-top: 22px;
    margin-bottom: 40px;
  }
  
}