@mixin mq($breakpoint) {
  @if $breakpoint == "smartphone" {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == "portrait" {
    @media (max-width: 1023px) {
      @content;
    }
  } @else if $breakpoint == "tablet" {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == "desktop-small" {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $breakpoint == "desktop" {
    @media (min-width: 1025px) {
      @content;
    }
  } @else if $breakpoint == "desktop-wide" {
    @media (min-width: 1400px) {
      @content;
    }
  } @else if $breakpoint == "tablet-only" {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  }
}
