@font-face {
	font-family: 'Whitney';
	src: url('/assets/fonts/whitney/Whitney-Medium.eot');
	src: url('/assets/fonts/whitney/Whitney-Medium.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/whitney/Whitney-Medium.woff2') format('woff2'),
	url('/assets/fonts/whitney/Whitney-Medium.woff') format('woff'),
	url('/assets/fonts/whitney/Whitney-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whitney';
	src: url('/assets/fonts/whitney/Whitney-MediumItalic.eot');
	src: url('/assets/fonts/whitney/Whitney-MediumItalic.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/whitney/Whitney-MediumItalic.woff2') format('woff2'),
	url('/assets/fonts/whitney/Whitney-MediumItalic.woff') format('woff'),
	url('/assets/fonts/whitney/Whitney-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
  }

@font-face {
	font-family: 'Whitney';
	src: url('/assets/fonts/whitney/Whitney-Bold.eot');
	src: url('/assets/fonts/whitney/Whitney-Bold.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/whitney/Whitney-Bold.woff2') format('woff2'),
	url('/assets/fonts/whitney/Whitney-Bold.woff') format('woff'),
	url('/assets/fonts/whitney/Whitney-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whitney';
	src: url('/assets/fonts/whitney/Whitney-BoldItalicSC.eot');
	src: url('/assets/fonts/whitney/Whitney-BoldItalicSC.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/whitney/Whitney-BoldItalicSC.woff') format('woff'),
	url('/assets/fonts/whitney/Whitney-BoldItalicSC.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whitney';
	src: url('/assets/fonts/whitney/Whitney-Light.eot');
	src: url('/assets/fonts/whitney/Whitney-Light.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/whitney/Whitney-Light.woff2') format('woff2'),
	url('/assets/fonts/whitney/Whitney-Light.woff') format('woff'),
	url('/assets/fonts/whitney/Whitney-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whitney';
	src: url('/assets/fonts/whitney/Whitney-LightItalic.eot');
	src: url('/assets/fonts/whitney/Whitney-LightItalic.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/whitney/Whitney-LightItalic.woff2') format('woff2'),
	url('/assets/fonts/whitney/Whitney-LightItalic.woff') format('woff'),
	url('/assets/fonts/whitney/Whitney-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whitney';
	src: url('/assets/fonts/whitney/Whitney-Book.eot');
	src: url('/assets/fonts/whitney/Whitney-Book.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/whitney/Whitney-Book.woff2') format('woff2'),
	url('/assets/fonts/whitney/Whitney-Book.woff') format('woff'),
	url('/assets/fonts/whitney/Whitney-Book.ttf') format('truetype');
	font-weight: 350;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whitney';
	src: url('/assets/fonts/whitney/Whitney-BookItalic.eot');
	src: url('/assets/fonts/whitney/Whitney-BookItalic.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/whitney/Whitney-BookItalic.woff2') format('woff2'),
	url('/assets/fonts/whitney/Whitney-BookItalic.woff') format('woff'),
	url('/assets/fonts/whitney/Whitney-BookItalic.ttf') format('truetype');
	font-weight: 350;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whitney';
	src: url('/assets/fonts/whitney/Whitney-Semibold.eot');
	src: url('/assets/fonts/whitney/Whitney-Semibold.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/whitney/Whitney-Semibold.woff2') format('woff2'),
	url('/assets/fonts/whitney/Whitney-Semibold.woff') format('woff'),
	url('/assets/fonts/whitney/Whitney-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whitney';
	src: url('/assets/fonts/whitney/Whitney-SemiboldItalic.eot');
	src: url('/assets/fonts/whitney/Whitney-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/whitney/Whitney-SemiboldItalic.woff2') format('woff2'),
	url('/assets/fonts/whitney/Whitney-SemiboldItalic.woff') format('woff'),
	url('/assets/fonts/whitney/Whitney-SemiboldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('/assets/fonts/whitney/Whitney-BlackSC.eot');
  src: url('/assets/fonts/whitney/Whitney-BlackSC.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/whitney/Whitney-BlackSC.woff2') format('woff2'),
  url('/assets/fonts/whitney/Whitney-BlackSC.woff') format('woff'),
  url('/assets/fonts/whitney/Whitney-BlackSC.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('/assets/fonts/whitney/Whitney-BlackItalic.eot');
  src: url('/assets/fonts/whitney/Whitney-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/whitney/Whitney-BlackItalic.woff2') format('woff2'),
  url('/assets/fonts/whitney/Whitney-BlackItalic.woff') format('woff'),
  url('/assets/fonts/whitney/Whitney-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/google/roboto-v27-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/google/roboto-v27-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/google/roboto-v27-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/google/roboto-v27-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/google/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('/assets/fonts/google/MaterialIcons-Regular.woff2') format('woff2'),
    url('/assets/fonts/google/MaterialIcons-Regular.woff') format('woff'),
    url('/assets/fonts/google/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
